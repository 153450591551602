import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queries } from "queries/index.query";
import BusinessFieldsType, {
	BusinessResponse,
	ProviderFieldsType,
	ReviewFieldsType,
	ServiceFieldsType,
} from "types/business.types";
import { DTOType, PaginateGenericType } from "types/generic.types";

const PAGINATE_LIMIT = Number(process.env.NEXT_PUBLIC_PAGINATE_LIMIT);

const fetchReviews = async (
	slug: string | undefined,
	pageParam = 0,
	limit = 10
): Promise<PaginateGenericType<ReviewFieldsType>> => {
	const _pageParam = (pageParam !== null && pageParam) || 0;
	const res = await axios.get(`/businesses/${slug}/reviews?offset=${_pageParam}&limit=${limit}`);
	return res.data.data;
};

const fetchServices = async (
	slug: string | undefined,
	pageParam = 0,
	limit = 10
): Promise<PaginateGenericType<ServiceFieldsType>> => {
	const _pageParam = (pageParam !== null && pageParam) || 0;
	const res = await axios.get(`/businesses/${slug}/services?offset=${_pageParam}&limit=${limit}`);
	return res.data.data;
};

const fetchProviders = async (
	slug: string | undefined,
	pageParam = 0,
	limit = 10
): Promise<PaginateGenericType<ProviderFieldsType>> => {
	const _pageParam = (pageParam !== null && pageParam) || 0;
	const res = await axios.get(`/businesses/${slug}/providers?offset=${_pageParam}&limit=${limit}`);
	return res.data.data;
};

const fetchAllServices = async (
	slug: string | undefined,
	pageParam = 0,
	data: PaginateGenericType<ServiceFieldsType> = {
		offset: 0,
		limit: 0,
		hasNext: true,
		items: [],
	},
	limit = 50
): Promise<any> => {
	const offset = pageParam * limit;
	return axios.get(`/businesses/${slug}/services?offset=${offset}&limit=${limit}`).then((res) => {
		console.log(`Fetch All Services, Offset: ${offset} - Limit: ${limit}`);
		data.items.push(...res.data.data.items);
		data.offset = res.data.data.offset;
		data.limit = res.data.data.limit;
		data.hasNext = res.data.data.hasNext;

		if (!res.data.data.hasNext) return data;

		return fetchAllServices(slug, pageParam + 1, data);
	});
};

const fetchBusinessProfile = async (
	slug: string | undefined,
	fields = "services,providers,reviews,locations"
): Promise<DTOType<BusinessFieldsType>> => {
	const req = await axios(`/businesses/${slug}?fields=${fields}`);
	return req.data;
};

const useBusinessProfile = () => {
	return useQuery<BusinessResponse>([queries.publicProfile.businessKey], {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchIntervalInBackground: false,
	});
};

const useReviews = (slug: string | undefined) => {
	return useInfiniteQuery(["reviews"], async ({ pageParam = 0 }) => fetchReviews(slug, pageParam, PAGINATE_LIMIT), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchIntervalInBackground: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.hasNext) {
				return pages.length * PAGINATE_LIMIT;
			} else {
				return undefined;
			}
		},
	});
};

const useServices = (slug: string | undefined) => {
	return useInfiniteQuery(["services"], async ({ pageParam = 0 }) => fetchServices(slug, pageParam, PAGINATE_LIMIT), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchIntervalInBackground: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.hasNext) {
				return pages.length * PAGINATE_LIMIT;
			} else {
				return undefined;
			}
		},
	});
};

const useProvider = (slug: string | undefined) => {
	return useInfiniteQuery(
		["providers"],
		async ({ pageParam = 0 }) => fetchProviders(slug, pageParam, PAGINATE_LIMIT),
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchIntervalInBackground: false,
			getNextPageParam: (lastPage, pages) => {
				if (lastPage.hasNext) {
					return (pages.length - 1) * PAGINATE_LIMIT + PAGINATE_LIMIT;
				} else {
					return undefined;
				}
			},
		}
	);
};

export {
	useBusinessProfile,
	useReviews,
	useServices,
	useProvider,
	fetchReviews,
	fetchProviders,
	fetchAllServices,
	fetchBusinessProfile,
};
