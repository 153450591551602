
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState, useRef, useEffect } from "react";
import { GetStaticPaths, GetStaticProps } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import I18nProvider from "next-translate/I18nProvider";
import useTranslation from "next-translate/useTranslation";
import { ParsedUrlQuery } from "querystring";
import classNames from "classnames";
import { businessProfileSSR } from "ssr/businessProfile.ssr";
import { useCanRender } from "hooks/useRender";
import { useQuery } from "@tanstack/react-query";
import { fetchBusinessProfile } from "hooks/useFetch";
import { queries } from "queries/index.query";
import useStickyNavbars from "hooks/useStickyNavbars";
import commonEN from "locales/en/common.json";
import commonFR from "locales/fr/common.json";
const Layout = dynamic(() => import("containers/Layout/Layout"), {
    ssr: true,
});
const NavigationTab = dynamic(() => import("containers/Tabs/Tab"), {
    ssr: true,
});
const Cover = dynamic(() => import("containers/Cover/Cover"), {
    ssr: true,
});
const AboutContainer = dynamic(() => import("containers/About/About"), {
    ssr: true,
});
const ProviderContainer = dynamic(() => import("containers/Providers/Providers"), {
    ssr: true,
});
const ServiceContainer = dynamic(() => import("containers/Services/Services"), {
    ssr: true,
});
const ReviewsContainer = dynamic(() => import("containers/Reviews/Reviews"), {
    ssr: true,
});
const Footer = dynamic(() => import("containers/Layout/Footer"), {
    ssr: false,
    loading: () => <>Loading...</>,
});
type IPageProps = {
    slug: string;
};
type IParamsStatic = ParsedUrlQuery & {
    slug: string;
};
function BusinessProfile({ slug }: IPageProps) {
    const { locale } = useRouter();
    const { lang } = useTranslation();
    const { data: businessProfile } = useQuery([queries.publicProfile.businessKey], () => fetchBusinessProfile(slug), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const profile = businessProfile?.data;
    const headerRef = useRef<any>();
    const tabRef = useRef<any>();
    const onHeaderRefChange = (ref: React.RefCallback<HTMLDivElement>) => {
        headerRef.current = ref;
    };
    useStickyNavbars(headerRef, tabRef);
    const [path, setPath] = useState("about");
    const isProviderRenderable = useCanRender("provider");
    const isReviewRenderable = useCanRender("review");
    const language = locale === "default" ? (businessProfile?.data.widget.settings.language.default as string) : lang;
    const common = language === "fr" ? commonFR : commonEN;
    useEffect(() => {
        if (locale === "default") {
            document.documentElement.lang = language;
        }
    }, [language, locale]);
    if (profile) {
        const widget = profile?.widget;
        return (<I18nProvider lang={language} namespaces={{ common }}>
				<Layout businessKey={profile.key} title={profile.name} description={profile.description} primaryColor={widget.settings.appearance?.secondaryColor} logoURL={profile.logo?.url} headerProps={{ onHeaderRefChange }} languages={businessProfile.data.widget.settings.language.availables}>
					<div className="container mx-auto max-w-screen-xl md:px-10">
						<Cover businessName={profile.name} businessLogo={profile.logo} rate={profile.rate} covers={profile.coverImages}/>

						<div ref={tabRef} id="tab" className="sticky top-16 pt-4 mb-8 bg-white/80 backdrop-blur-md z-20">
							<NavigationTab path={path} setPath={setPath}/>
						</div>

						<div className="px-4 md:px-0 pb-10">
							<div id="about" className={classNames("section", { hidden: path !== "about" })}>
								<AboutContainer profile={profile}/>
								<Footer />
							</div>

							{isProviderRenderable && (<div id="providers" className={classNames("section", { hidden: path !== "providers" })}>
									<ProviderContainer providersCount={profile.providers.items.length}/>
								</div>)}
							<div id="services" className={classNames("section", { hidden: path !== "services" })}>
								<ServiceContainer services={profile.services}/>
							</div>

							{isReviewRenderable && (<div id="reviews" className={classNames("section", { hidden: path !== "reviews" })}>
									<ReviewsContainer reviews={profile.reviews}/>
								</div>)}
						</div>
						{process.env.NODE_ENV !== "production" && (<div className="max-w-full">
								<pre className="bg-gray-200 rounded-lg px-6 py-4 dark:bg-zinc-800 text-black dark:text-gray-50 text-sm whitespace-pre-wrap mt-4 break-word">
									{JSON.stringify(profile, null, 2)}
								</pre>
							</div>)}
					</div>
				</Layout>
			</I18nProvider>);
    }
}
export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
    let paths: {
        params: ParsedUrlQuery;
        locale?: string | undefined;
    }[] = [];
    return {
        paths,
        fallback: "blocking",
    };
};
const getStaticProps: GetStaticProps<IPageProps, IParamsStatic> = async ({ params, locale }) => {
    const slug = params?.slug;
    if (!slug)
        return { notFound: true };
    const props = await businessProfileSSR({ slug, locale });
    return props;
};
export default BusinessProfile;

    async function __Next_Translate__getStaticProps__1920699e353__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[slug]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1920699e353__ as getStaticProps }
  