import { useBusinessProfile } from "./useFetch";
import { RenderMethod } from "types/business.types";

export const useCanRender = (section: "provider" | "review" | "location") => {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data.widget;
	const renderMethod = widgetSetting?.settings[section].renderMethod;

	return renderMethod !== RenderMethod.NoRender;
};

export const useHowManyItems = (section: "providers" | "reviews" | "services" | "locations") => {
	const { data: businessProfile } = useBusinessProfile();
	const sectionJSON = businessProfile?.data[section];
	const lengthOfItems = sectionJSON?.items.length || 0;

	return lengthOfItems;
};
