import { DTOType, PaginateGenericType } from "./generic.types";

export type Currency = {
	symbol: string;
	name: string;
};

export type Industry = {
	id: number;
	name: string;
};

export type Image = {
	url: string;
	alt: string;
	sizes: ImageDimensions[];
};

export type Rate = {
	score: number;
	count: number;
};

type ImageDimensions = {
	width: number;
	height: number;
};

export type PhoneNumber = {
	countryName: string;
	countryCode: string;
	number: number;
	formattedNumber: string;
};

type Address = {
	placeId: string;
	plusCode: string;
	geoLocation: {
		latitude: number;
		longitude: number;
	};
	country: string;
	state: string;
	city: string;
	route: string;
	postalCode: string;
	unit: string;
	displayAddress: string;
};

export type User = {
	firstName: string;
	lastName: string;
	avatar: Image;
};

type Category = {
	id: number;
	name: string;
};

const Weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;
export type WorkingHourItem = {
	start: string;
	end: string;
};

export type WorkingHour = {
	[K in typeof Weekdays[number]]: WorkingHourItem[];
};

const Links = ["website", "facebook", "twitter", "instagram", "youtube"] as const;
export type PublicLinks =
	| {
			[K in typeof Links[number]]: string;
	  }
	| null;

export enum AppointmentType {
	InPerson = "in-person",
	Virtual = "virtual",
}

export type LocationFieldsType = {
	id: number;
	name: string;
	timeZone: string;
	phone: PhoneNumber;
	address: Address;
};

export type ServiceFieldsType = {
	id: number;
	name: string;
	price: number;
	duration: number;
	description: string;
	image: Image;
	appointmentType: AppointmentType;
	category: Category;
};

export type ProviderFieldsType = {
	id: number;
	name: string;
	rate: Rate;
	description: string;
	image: Image;
};

export type ReviewFieldsType = {
	author: User;
	publishedAt: Date;
	score: number;
	comment: string;
};

// ENUMs
export enum RenderMethod {
	Categorized = "categorized",
	Plain = "plain",
	NoRender = "no-render",
}

export enum AvailableLanguages {
	EN = "en",
	FR = "fr",
}

// export enum ServiceUnavailable {
// 	Price = "price",
// 	Duration = "duration",
// 	Description = "description ",
// }
export const ServiceUnavailable = ["description", "price", "duration", "image"] as const;

// export enum ProviderUnavailable {
// 	"rate",
// }
export const ProviderUnavailable = ["rate", "image"] as const;

type Translations = {
	[key: string]: string;
};

type Appearance = {
	primaryColor: string;
	secondaryColor: string;
};

// type ServiceRenderMethod = {} & RenderMethod;
type Service = {
	label: string;
	renderMethod: RenderMethod | string;
	unavailableItems: typeof ServiceUnavailable;
};

// type ProviderRenderMethod = {} & RenderMethod;
type Provider = {
	label: string;
	renderMethod: RenderMethod | string;
	unavailableItems: typeof ProviderUnavailable;
};

// type LocationRenderMethod = {} & RenderMethod;
type Location = {
	label: string;
	renderMethod: RenderMethod | string;
	unavailableItems: string[];
};

type Review = {
	label: string;
	renderMethod: RenderMethod | string;
	unavailableItems: string[];
};

type Settings = {
	service: Service;
	provider: Provider;
	location: Location;
	review: Review;
	appearance: Appearance;
	language: {
		availables: AvailableLanguages[];
		default: AvailableLanguages;
		translations: Translations;
	};
};

export type WidgetType = {
	uniqueIdentifier: string;
	settings: Settings;
};

type BusinessFieldsType = {
	key: string;
	name: string;
	rate: Rate;
	logo: Image;
	coverImages: Image[];
	currency: Currency;
	industry: Industry;
	phoneNumber: PhoneNumber;
	description: string;
	workingHours: WorkingHour;
	publicLinks: PublicLinks;
	widget: WidgetType;
	locations: PaginateGenericType<LocationFieldsType>;
	services: PaginateGenericType<ServiceFieldsType>;
	providers: PaginateGenericType<ProviderFieldsType>;
	reviews: PaginateGenericType<ReviewFieldsType>;
};

export type BusinessResponse = DTOType<BusinessFieldsType>;

export default BusinessFieldsType;
