import React, { useEffect } from "react";

function useStickyNavbars(headerRef: React.RefObject<HTMLDivElement>, tabRef: React.RefObject<HTMLDivElement>) {
	const $header = headerRef.current;
	const $tab = tabRef.current;
	useEffect(() => {
		if ($tab && $header) {
			const headerHeight = $header.clientHeight;
			$tab.style.top = `${headerHeight}px`;
		}
	}, [$header, $tab]);
}

export default useStickyNavbars;
